<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2>Agregar Empleado</h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Nombre:</label>

        <v-text-field
          v-model="nombre"
          variant="solo"
          hide-details
          autocomplete="off"
          pa-1
          clearable
          ma-0
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Apellido:</label>

        <v-text-field
          v-model="apellido"
          variant="solo"
          autocomplete="off"
          hide-details
          pa-1
          clearable
          ma-0
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Rut:</label>

        <v-text-field
          v-model="rut"
          variant="solo"
          hide-details
          autocomplete="off"
          pa-1
          ma-0
          placeholder="12.345.678-9"
          @update:modelValue="formatearRut(item)"
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Email:</label>

        <v-text-field
          v-model="email"
          variant="solo"
          hide-details
          autocomplete="off"
          pa-1
          clearable
          ma-0
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Intocable:</label>

        <v-checkbox v-model="intocable" hide-details></v-checkbox>
      </div>

      <v-btn
        @click="addEmpleado"
        style="
          background-color: #a3a3a3;
          padding: 15px;
          border-radius: 15px;
          margin-top: 2.4em;
          color: white;
          display: flex;
        "
        dark
        :disabled="!nombre || !apellido || !rut || !email"
      >
        Guardar
      </v-btn>
    </div>

    <br />
    <h2>Empleados</h2>
    <div class="d-flex flex-wrap ga-3 mb-3">
      <div class="w-in">
        <v-text-field
          v-model="search"
          label="Buscar por rut, nombre, email o status"
          prepend-inner-icon="mdi-magnify"
          single-line
          variant="solo"
          autocomplete="off"
          hide-details
        ></v-text-field>
      </div>
      <div class="w-in">
        <v-combobox
          :items="lstOrigen"
          variant="solo"
          v-model="origenSel"
          hide-details
          pa-1
          autocomplete="off"
          label="Origen"
          ma-0
          @update:search="filtrarEmpleados"
          item-title="nombre"
          item-value="id"
        >
        </v-combobox>
      </div>
      <div class="w-in">
        <v-combobox
          :items="lstIntocable"
          variant="solo"
          label="Intocable"
          v-model="intocableSel"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          @update:search="filtrarEmpleados"
          item-title="nombre"
          item-value="id"
        >
        </v-combobox>
      </div>
    </div>

    <v-data-table :headers="headers" :items="lstEmpleados" :search="search">
      <template v-slot:item.intocable="{ item }">
        <v-checkbox
          v-model="item.intocable"
          hide-details
          pa-1
          ma-0
          disabled
          autocomplete="off"
          class="mt-2"
        ></v-checkbox>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon icon="mdi-update" color="red" @click="showUpdateDialog(item)" />
        <v-icon icon="mdi-delete" color="red" @click="showDeleteDialog(item)" />
      </template>
    </v-data-table>
  </div>

  <v-dialog v-model="dialogDelete" persistent width="auto">
    <v-card>
      <v-card-title class="text-h5"> Borrar Empleado </v-card-title>
      <v-card-text> Esta seguro que desea borrar este empleado? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="dialogDelete = false">No</v-btn>
        <v-btn color="primary" @click="deleteEmpleadoTable(empleadoToDelete)"
          >Si</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogUpdate" persistent width="1024">
    <v-card>
      <v-card-title
        style="text-align: center !important; margin-top: 15px !important"
      >
        <span class="text-h6">Actualizar Empleado</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" md="4">
              <v-combobox
                :items="lstStatus"
                variant="solo"
                v-model="emplStatusUpd"
                hide-details
                pa-1
                autocomplete="off"
                label="Status"
                ma-0
                item-title="nombre"
                item-value="id"
              >
              </v-combobox>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="emplEmailUpd"
                variant="solo"
                hide-details
                pa-1
                ma-0
                autocomplete="off"
                label="Email"
              >
              </v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-checkbox
                label="Intocable"
                v-model="emplIntUpd"
                variant="solo"
                hide-details
                pa-1
                ma-0
              >
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row>
            <v-col align-self="center">
              <v-sheet style="display: flex; justify-content: center">
                <v-btn
                  style="
                    background-color: #a3a3a3;
                    padding: 15px;
                    border-radius: 15px;
                    color: white;
                    display: flex;
                  "
                  variant="text"
                  @click="dialogUpdate = false"
                >
                  Cerrar
                </v-btn>
                <v-btn
                  :disabled="!emplStatusUpd || !emplEmailUpd"
                  style=" background-color: #0071bc; padding: 15px; border-radius: 15px; color: white; display: flex; "
                  variant="text"
                  @click="updateEmpleado(empleadoToUpdate)"
                >
                  Actualizar
                </v-btn>
              </v-sheet>
            </v-col>
          </v-row></v-container
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import axios from "axios";
import swal from "sweetalert2";
import { formatRut } from "rutlib";
import { validateRut } from "rutlib";
import { useStore } from "vuex";
import { key } from "@/store";
import type { Empleado } from "../types/empleado";
import type { Status } from "../types/status";
import type { Origen } from "../types/origen";
import type { Intocable } from "../types/intocable";
const store = useStore(key);

let isLoading: Ref<boolean> = ref(false);
let nombre: Ref<string | null> = ref(null);
let apellido: Ref<string | null> = ref(null);
let rut: Ref<string | null> = ref(null);
let email: Ref<string | null> = ref(null);

let headers: any = [
  { title: "Rut", key: "rut", width: "100px" },
  { title: "Nombre", key: "fullName", align: "start", width: "150px" },
  { title: "Email", key: "email", align: "start", width: "100px" },
  { title: "Status", key: "status", align: "center", width: "50px" },
  {
    title: "Intocable",
    key: "intocable",
    align: "left",
    width: "50px",
  },
  { title: "", key: "actions", align: "center", width: "25px" },
];

let lstEmpleados: Ref<Empleado[]> = ref([]);
let search: Ref<string> = ref("");
let dialogDelete: Ref<boolean> = ref(false);
let dialogUpdate: Ref<boolean> = ref(false);

let empleadoToDelete: Ref<Empleado | null> = ref(null);
let empleadoToUpdate: Ref<Empleado | null> = ref(null);

let lstStatus: Status[] = [
  { id: "activo", nombre: "activo" },
  { id: "inactivo", nombre: "inactivo" },
];

let emplStatusUpd: Ref<Status | null> = ref(null);
let emplEmailUpd: Ref<string> = ref("");
let emplIntUpd: Ref<boolean> = ref(false);

let intocable: Ref<boolean> = ref(false);

let lstOrigen: Origen[] = [
  { id: "Todos", nombre: "Todos" },
  { id: "Buk", nombre: "Buk" },
  { id: "No Buk", nombre: "No Buk" },
];

let lstIntocable: Intocable[] = [
  { id: "Ambas", nombre: "Ambas" },
  { id: "Si", nombre: "Si" },
  { id: "No", nombre: "No" },
];

let origenSel: Ref<Origen> = ref({ id: "Todos", nombre: "Todos" });
let intocableSel: Ref<Intocable> = ref({ id: "Ambas", nombre: "Ambas" });

// computed: {
//   isValidEmail() {
//     return /^[^@]+@\w+(\.\w+)+\w$/.test(this.email);
//   },
// },

onMounted(async () => {
  try {
    store.state.titulo = "Mantenedor Empleados";

    await getEmpleados();
  } catch (error) {
    console.log(error);
  }
});

async function getEmpleados() {
  try {
    isLoading.value = true;

    await axios
      .get(
        store.state.url +
          "api/Empleado/GetEmpleadosNoBuk?origen=" +
          origenSel.value.id +
          "&intocable=" +
          intocableSel.value.id,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          lstEmpleados.value = response.data as Empleado[];
          //   console.log(JSON.stringify(this.lstEmpleados));
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function addEmpleado() {
  try {
    if (nombre.value == null || nombre.value.trim() == "") {
      swal.fire({
        icon: "warning",
        title: "Debe ingresar un nombre",
        showConfirmButton: true,
      });
      return;
    }

    if (apellido.value == null || apellido.value.trim() == "") {
      swal.fire({
        icon: "warning",
        title: "Debe ingresar un apellido",
        showConfirmButton: true,
      });
      return;
    }

    if (rut.value == null || rut.value.trim() == "") {
      swal.fire({
        icon: "warning",
        title: "Debe ingresar un rut",
        showConfirmButton: true,
      });
      return;
    }

    if (email.value == null || email.value.trim() == "") {
      swal.fire({
        icon: "warning",
        title: "Debe ingresar un email",
        showConfirmButton: true,
      });
      return;
    }

    if (!validateRut(rut.value)) {
      swal.fire({
        icon: "warning",
        title: "Rut no valido",
        showConfirmButton: true,
      });
      return;
    }

    let empleado: Empleado = {
      id: 0,
      personId: 0,
      firstName: nombre.value,
      surname: apellido.value,
      fullName: nombre.value + " " + apellido.value,
      email: email.value,
      personalEmail: email.value,
      rut: rut.value,
      status: "activo",
      buk: false,
      intocable: intocable.value,

      pictureUrl: "",
      secondSurname: "",
      currentJobRoleId: 0,
      currentJobBossId: 0,
      currentJobLocationId: 0,
      currentJobBossRut: "",
      currentJobRoleName: "",
    };

    await axios
      .post(store.state.url + "api/Empleado/PostEmpleado", empleado, {
        headers: {},
      })
      .then(async (response) => {
        if (response.status == 200) {
          lstEmpleados.value.unshift(empleado);
          console.log("Empleado agregado");

          swal.fire({
            icon: "success",
            title: "Empleado Creado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });

          // await getEmpleados();

          nombre.value = null;
          apellido.value = null;
          rut.value = null;
          email.value = null;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function formatearRut() {
  if (rut.value == null) {
    return;
  }

  let newRut = rut.value.replace(/[^0-9kK]+/g, "");
  rut.value = formatRut(newRut, true);
}

async function deleteEmpleado(item: Empleado) {
  try {
    await axios
      .delete(
        store.state.url + "api/Empleado/DeleteEmpleado?id=" + item.personId,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          //this.getRoles();
          console.log("Empleado eliminado");
          // await getEmpleados();
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function deleteEmpleadoTable(item: Empleado) {
  deleteEmpleado(item).then(() => {
    const index = lstEmpleados.value.indexOf(item);
    lstEmpleados.value.splice(index, 1);

    dialogDelete.value = !dialogDelete.value;
  });
}

async function updateEmpleado(item: Empleado) {
  try {
    var empleado = item;

    empleado.status = emplStatusUpd.value?.id;
    empleado.email = emplEmailUpd.value;
    empleado.intocable = emplIntUpd.value;

    empleado.pictureUrl = "";
    empleado.secondSurname = "";
    empleado.currentJobRoleId = 0;
    empleado.currentJobBossId = 0;
    empleado.currentJobLocationId = 0;
    empleado.currentJobBossRut = "";
    empleado.currentJobRoleName = "";

    await axios
      .put(store.state.url + "api/Empleado/UpdateEmpleado", empleado, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          console.log("Empleado actualizado");

          swal.fire({
            icon: "success",
            title: "Empleado Actualizado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });

          dialogUpdate.value = !dialogUpdate.value;

          // window.location.reload();
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

function showDeleteDialog(item: Empleado) {
  empleadoToDelete.value = item;
  dialogDelete.value = !dialogDelete.value;
}

function showUpdateDialog(item: Empleado) {
  empleadoToUpdate.value = item;

  let emp = lstEmpleados.value.find((x) => x.email == item.email);

  emplEmailUpd.value = emp?.email || "";

  emplStatusUpd.value = lstStatus.find((x) => x.id == item.status) || null;
  emplIntUpd.value = item.intocable || false;

  dialogUpdate.value = !dialogUpdate.value;
}

async function filtrarEmpleados() {
  if (typeof origenSel.value != "string" && origenSel.value != null) {
    await getEmpleados();
  }
}
</script>
