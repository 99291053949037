<style>
.v-table > .v-table__wrapper > table > thead > tr > th {
  padding: 0 4px !important;
}

.swal2-confirm.swal2-styled {
  background-color: #a61a2f !important;
  box-shadow: none !important;
}

.swal2-warning {
  border-color: #a61a2f !important;
  color: #a61a2f !important;
}

.swal2-show {
  border-radius: 15px !important;
}

.v-btn__content {
  display: flex;
  flex-direction: column;
}
</style>

<template>
  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 15px; font-weight: 600; border-bottom: 1px solid">
      {{ "SOC-" + code.toString().padStart(7, "0") }} - Datos Generales
    </h2>

    <div class="d-flex flex-wrap ga-3">
      <div class="w-in">
        <label>Tipo Solicitud:</label>

        <v-text-field
          v-model="tipo"
          readonly
          pa-1
          ma-0
          autocomplete="off"
          hide-details
          variant="solo"
          color="primary"
          required
        />
      </div>

      <div class="w-in">
        <label>Estado Solicitud:</label>

        <v-text-field
          v-model="estado"
          readonly
          pa-1
          hide-details
          ma-0
          autocomplete="off"
          variant="solo"
          color="primary"
          required
        />
      </div>

      <div class="w-in">
        <label>Fecha Solicitud:</label>

        <v-text-field
          v-model="date"
          readonly
          autocomplete="off"
          pa-1
          hide-details
          ma-0
          variant="solo"
          color="primary"
          required
        />
      </div>

      <div class="w-in">
        <label>Rut Ingresado Por:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          ma-0
          autocomplete="off"
          v-model="solPorId"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Ingresado Por:</label>
        <v-text-field
          readonly
          variant="solo"
          autocomplete="off"
          pa-1
          ma-0
          v-model="solPorNombre"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Empresa:</label>

        <v-combobox
          v-model="empresaSel"
          :items="lstEmpresas"
          autocomplete="off"
          variant="solo"
          pa-1
          hide-details
          ma-0
          readonly
          item-title="nameDisplayName"
          item-value="id"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Departamento:</label>

        <v-combobox
          v-model="departamentoSel"
          :items="lstDepartamento"
          variant="solo"
          readonly
          autocomplete="off"
          hide-details
          pa-1
          ma-0
          item-title="codeDisplayName"
          item-value="code"
        >
        </v-combobox>
      </div>

      <div class="w-in">
        <label>Rut Solicitante:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          ma-0
          autocomplete="off"
          v-model="solId"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Solicitante:</label>
        <v-text-field
          readonly
          variant="solo"
          pa-1
          autocomplete="off"
          ma-0
          v-model="solNombre"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in" v-if="tipo == 'Compra'">
        <label>Proveedor:</label>

        <v-combobox
          v-model="vendorSel"
          readonly
          hide-details
          autocomplete="off"
          :items="lstVendors"
          variant="solo"
          item-title="numberDisplayName"
          item-value="id"
        >
        </v-combobox>
      </div>

      <div class="w-in" v-if="tipo == 'Compra'">
        <label>Comprador:</label>

        <v-autocomplete
          v-if="estado == 'Enviada'"
          v-model="compradorSel"
          :items="lstCompradores"
          autocomplete="off"
          variant="solo"
          item-title="rutDisplayName"
          item-value="rut"
          readonly
          hide-details
          return-object
        >
        </v-autocomplete>

        <v-autocomplete
          v-else
          v-model="compradorSel"
          :items="lstCompradores"
          autocomplete="off"
          variant="solo"
          item-title="rutDisplayName"
          item-value="rut"
          clearable
          hide-details
          return-object
        >
        </v-autocomplete>
      </div>

      <div class="w-in" v-if="tipo === 'Retiro Bodega'">
        <label>Cod. Bod:</label>

        <v-combobox
          variant="solo"
          :items="lstBodegas"
          hide-details
          pa-1
          autocomplete="off"
          ma-0
          readonly
          item-title="codeDisplayName"
          item-value="id"
          :return-object="false"
          v-model="bodegaSel"
        >
        </v-combobox>
      </div>

      <div v-if="tipo == 'Retiro Bodega'" class="w-in">
        <label>Codigo Despacho:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          autocomplete="off"
          ma-0
          v-model="codigoDes"
          hide-details
        >
        </v-text-field>
      </div>

      <div v-if="tipo == 'Compra'" class="w-in">
        <label>Num. BC365:</label>

        <v-text-field
          variant="solo"
          readonly
          pa-1
          autocomplete="off"
          ma-0
          v-model="idBc365"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in">
        <label>Observación:</label>

        <v-textarea
          readonly
          variant="solo"
          pa-1
          ma-0
          autocomplete="off"
          v-model="obs"
          hide-details
        >
        </v-textarea>
      </div>

      <div v-if="estado != 'Creada'" class="w-in">
        <label v-if="estado == 'Rechazada'">Rechazada Por:</label>
        <label v-if="estado == 'Autorizada'">Autorizada Por:</label>
        <label
          v-if="
            estado == 'Aprobada' ||
            estado == 'Enviada' ||
            estado == 'En Despacho' ||
            estado == 'Despachado' ||
            estado == 'Cerrada'
          "
          >Aprobada Por:</label
        >

        <v-text-field
          readonly
          variant="solo"
          pa-1
          ma-0
          autocomplete="off"
          v-model="nombreAutorizador"
          hide-details
        >
        </v-text-field>
      </div>

      <div class="w-in" v-if="estado != 'Creada'">
        <label>Motivo:</label>

        <v-text-field
          readonly
          variant="solo"
          pa-1
          ma-0
          v-model="motivo"
          hide-details
        >
        </v-text-field>
      </div>

      <div v-if="adjuntos.length > 0" class="w-in">
        <label>{{ adjuntos.length }} Archivos Adjuntos:</label>

        <div class="text-center d-flex flex-wrap">
          <div
            v-for="(adj, index) in adjuntos"
            :key="index"
            style="align-items: center"
          >
            <v-tooltip :text="adj.nombre">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"
                  class="ma-2"
                  style="
                    height: 55px;
                    background-color: #a61a2f;
                    color: white;
                    border-radius: 11px;
                  "
                  @click="bajarAdjunto(sliceString(adj.url))"
                >
                  <v-icon size="x-large">{{ setIcon(adj.nombre) }}</v-icon>
                  <div>Archivo {{ index + 1 }}</div>
                </v-btn>
              </template>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    style="
      padding: 20px;
      margin: 20px;
      background-color: white;
      border-radius: 10px;
    "
  >
    <h2 style="margin-bottom: 6px; font-weight: 600; border-bottom: 1px solid">
      Detalle Solicitud
    </h2>

    <div style="display: inline-flex; float: right">
      <v-btn
        prepend-icon="mdi-refresh"
        style="
          background-color: #107167;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin: 5px 0px 10px 10px;
          display: flex;
          height: 36px;
        "
        :enabled="enabledBC365"
        v-if="esComprador && tipo == 'Compra' && estado == 'Aprobada'"
        @click="updateComprador()"
        >ACTUALIZAR COMPRADOR</v-btn
      >

      <v-btn
        prepend-icon="mdi-export"
        v-on:click="
          exportarPdf('SOC-' + code.toString().padStart(7, '0') + '.pdf')
        "
        style="
          margin: 5px 0px 10px 7px;
          background-color: rgb(166 26 47);
          border-radius: 15px;
          color: white;
          margin-bottom: 8px;
        "
      >
        <template v-slot:prepend>
          <v-icon color="white"></v-icon>
        </template>

        Exportar
      </v-btn>
    </div>

    <v-data-table
      :headers="headers"
      :items="lstDetalle"
      item-key="id"
      v-if="tipo == 'Compra'"
    >
      <template v-slot:item.isSelected="{ item }">
        <v-checkbox
          v-if="estado == 'Aprobada' || estado == 'En Envio'"
          hide-details
          color="red"
          v-model="item.isSelected"
          :readonly="item.estadoDespacho == 'Enviada'"
        ></v-checkbox>

        <div v-else></div>
      </template>

      <template v-slot:item.material="{ item }">
        {{ item.material }}
      </template>

      <template v-slot:item.precio="{ item }">
        {{ formatMoney(item.precio) }}
      </template>

      <template v-slot:item.total="{ item }">
        {{ formatTotal(item.total) }}
      </template>
    </v-data-table>

    <v-data-table
      :headers="headersRetiro"
      :items="lstDetalle"
      v-if="tipo == 'Retiro Bodega'"
    >
      <template v-slot:item.codigoDespacho="{ item }">
        <div v-if="item.retiroBodega && esBodeguero">
          <div v-if="getEstadoDespacho(item)">
            <v-text-field
              v-model="item.codigoDespacho"
              variant="outlined"
              density="compact"
              hide-details
              autocomplete="off"
              pa-1
              ma-0
            />
          </div>

          <div v-else>
            <v-text-field
              v-model="item.codigoDespacho"
              variant="outlined"
              density="compact"
              hide-details
              pa-1
              autocomplete="off"
              ma-0
              readonly
            />
          </div>
        </div>

        <div v-else>
          <v-text-field
            v-model="item.codigoDespacho"
            variant="outlined"
            density="compact"
            hide-details
            pa-1
            autocomplete="off"
            ma-0
            disabled
          />
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div v-if="item.retiroBodega && esBodeguero">
          <div v-if="getEstadoDespacho(item)">
            <v-btn
              @click="despacharItem(item)"
              style="
                background-color: #10510e;
                margin-top: 10px;
                border-radius: 15px;
                color: white;
                margin-left: 10px;
              "
              >Despachar</v-btn
            >
          </div>
        </div>
      </template>
    </v-data-table>

    <v-data-table
      v-if="tipo == 'Rendicion'"
      :headers="headersRendicion"
      :items="lstDetalle"
    >
      <template v-slot:item.fechaDoc="{ item }">
        {{ item.fechaDoc }}
      </template>

      <template v-slot:item.descGasto="{ item }">
        {{ item.descGasto }}
      </template>

      <template v-slot:item.monto="{ item }">
        {{ formatMoney(item.monto) }}
      </template>
    </v-data-table>

    <div
      v-if="
        tipo != 'Rendicion' && estado == 'Creada' && aprobador == 'Aprobador'
      "
      style="margin-top: 10px"
      class="class-btn"
    >
      <v-btn
        width="200"
        style="
          margin: 5px;
          background-color: #a61a2f;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogRechazar = true"
        >Rechazar Solicitud</v-btn
      >

      <div v-if="(estado = 'Creada')">
        <v-btn
          width="200"
          style="
            margin: 5px;
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            margin-right: auto;
            display: flex;
          "
          density="default"
          @click="cambiarEstadoSolicitud('Guardada')"
          >Editar Solicitud</v-btn
        >
      </div>
      <v-btn
        width="200"
        style="
          background-color: #10510e;
          padding: 15px;
          margin: 5px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogAprobar = true"
        >Aprobar Solicitud</v-btn
      >
    </div>

    <div
      v-if="tipo != 'Retiro Bodega'"
      style="
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-right: 10px;
        display: flex;
        justify-content: flex-end;
      "
    >
      Total: {{ formatTotal(total) }}
    </div>

    <div
      v-if="
        tipo == 'Rendicion' &&
        estado == 'Creada' &&
        esAutorizador &&
        aprobador == 'Autorizador'
      "
      style="display: flex; margin-top: 10px"
    >
      <v-btn
        width="200"
        style="
          background-color: #a61a2f;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogRechazar = true"
        >Rechazar Solicitud</v-btn
      >

      <div v-if="(estado = 'Creada')">
        <v-btn
          width="200"
          style="
            margin: 5px;
            background-color: #595959;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            margin-right: auto;
            display: flex;
          "
          density="default"
          @click="cambiarEstadoSolicitud('Guardada')"
          >Editar Solicitud</v-btn
        >
      </div>

      <v-btn
        width="200"
        style="
          background-color: #10510e;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          margin-right: auto;
          display: flex;
        "
        density="default"
        @click="dialogAutorizar = true"
        >Autorizar Solicitud</v-btn
      >
    </div>

    <div style="display: flex">
      <v-btn
        style="
          width: 200px;
          background-color: #10510e;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: 5px;
          display: flex;
        "
        :enabled="enabledBC365"
        v-if="esComprador && (estado == 'Aprobada' || estado == 'En Envio')"
        @click="enviarBusinessCentral()"
      >
        Enviar a BC365
      </v-btn>

      <v-btn
        style="
          width: 200px;
          background-color: #595959;
          padding: 15px;
          border-radius: 15px;
          color: white;
          margin-left: auto;
          display: flex;
        "
        v-if="estado == 'Rechazada'"
        @click="navigate()"
      >
        Copiar Solicitud
      </v-btn>
    </div>

    <div style="display: flex; justify-content: end">
      <div
        v-if="
          (esAdmin || esComprador || esBodeguero || esContabilidad) &&
          (estado == 'Aprobada' || estado == 'Autorizada')
          // && nulleable
        "
      >
        <v-btn
          style="
            background-color: #575757;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
          "
          @click="anularSolicitud()"
        >
          Anular
        </v-btn>
      </div>

      <div style="display: flex; margin-top: 10px">
        <v-btn
          style="
            background-color: #a61a2f;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
          "
          v-if="esBodeguero && estado == 'En Despacho'"
          @click="dialogCerrar = true"
        >
          Cerrar Solicitud
        </v-btn>

        <v-btn
          style="
            width: 200px;
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: 10px;
            display: flex;
          "
          v-if="esBodeguero"
          @click="dialogDespachar = true"
        >
          Despachar Solicitud
        </v-btn>
      </div>

      <div v-if="estado == 'Autorizada' && esContabilidad">
        <v-btn
          style="
            width: 200px;
            background-color: #10510e;
            padding: 15px;
            border-radius: 15px;
            color: white;
            margin-left: auto;
            display: flex;
            margin-left: 5px;
          "
          @click="enviarRendicion()"
        >
          Enviar Rendición
        </v-btn>
      </div>
    </div>
  </div>

  <v-row justify="center">
    <v-dialog v-model="dialogRechazar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea rechazar esta solicitud?</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea
                variant="solo"
                autocomplete="off"
                v-model="motivo"
                label="Motivo del rechazo*"
                required
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogRechazar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cambiarEstadoSolicitud('Rechazada')"
          >
            Si, rechazar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogAprobar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea aprobar esta solicitud?</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea
                variant="solo"
                v-model="motivo"
                autocomplete="off"
                label="Motivo del apruebo*"
                required
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAprobar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cambiarEstadoSolicitud('Aprobada')"
          >
            Si, aprobar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogAutorizar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea autorizar esta solicitud?</span
          >
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-textarea
                variant="solo"
                v-model="motivo"
                label="Motivo de la autorizacion*"
                required
                autocomplete="off"
              ></v-textarea>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogAutorizar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cambiarEstadoSolicitud('Autorizada')"
          >
            Si, autorizar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogDespachar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea despachar esta solicitud?</span
          >
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogDespachar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="despacharSolicitud()"
          >
            Si, despachar!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-row justify="center">
    <v-dialog v-model="dialogCerrar" persistent width="50%">
      <v-card>
        <v-card-title>
          <span class="text-h5"
            >¿Esta seguro que desea cerrar esta solicitud?</span
          >
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="dialogCerrar = false"
          >
            No!
          </v-btn>
          <v-btn
            color="blue-darken-1"
            variant="text"
            @click="cerrarDespachado()"
          >
            Si!
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

  <v-overlay
    :model-value="isLoading"
    class="align-center justify-center"
    persistent
  >
    <v-progress-circular
      color="#a61a2f"
      size="50"
      :width="5"
      indeterminate
    ></v-progress-circular>
  </v-overlay>
</template>

<script setup lang="ts">
import type { Ref } from "vue";
import { reactive, ref } from "vue";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { key } from "@/store";
import axios from "axios";
import { useCookies } from "vue3-cookies";
import { useRouter, useRoute } from "vue-router";
import swal from "sweetalert2";
import { PublicClientApplication } from "@azure/msal-browser";
import AzureService from "../services/AzureService";
import type { SolicitudCompra } from "../types/solicitudCompra";
import type { Company } from "../types/company";
import type { Departamento } from "../types/departamento";
import type { Vendors } from "../types/vendors";
import type { Empleado } from "../types/empleado";
import type { DimensionCompany } from "../types/dimensionCompany";
import type { Adjunto } from "../types/adjunto";
import type { Location } from "../types/location";
import type { DepartamentoEmpleado } from "../types/departamentoEmpleado";
import type { SolicitudDetalle } from "../types/solicitudDetalle";
import type { Rol } from "../types/rol";
import type { PurchaseOrdersBody } from "../types/PurchaseOrdersBody";
import type { Proyecto } from "../types/proyecto";
import type { PurchaseOrderLine } from "../types/purchaseOrderLine";
import type { DimensionSetLine } from "../types/dimensionSetLine";

import useDepartamentos from "../composables/useDepartamento";
import useCeco from "../composables/useCeco";
import useVendors from "../composables/useVendors";
import useComprador from "../composables/useComprador";
import useBodegas from "../composables/useBodega";
import useProyecto from "../composables/useProyecto";
import useSolicitante from "../composables/useSolicitante";

const { cookies } = useCookies();
const store = useStore(key);
const router = useRouter();
const route = useRoute();

const { getDepartamentos, lstDepartamento } = useDepartamentos();
const { getCeco, lstCeco } = useCeco();
const { getVendors, lstVendors } = useVendors();
const { getEmpleadoComprador, lstCompradores } = useComprador();
const { getBodegas, lstBodegas } = useBodegas();
const { getProyectos, lstProyectos } = useProyecto();
const { getSolicitantes, lstSolicitantes } = useSolicitante();

let $msalInstance: PublicClientApplication;
let isLoading: Ref<boolean> = ref(true);

let idSolicitud: string = "";
let date: Ref<string> = ref("");
let code: Ref<number> = ref(0);
let obs: Ref<string> = ref("");
let solPorId: Ref<string> = ref("");
let solPorNombre: Ref<string> = ref("");

let lstEmpresas: Ref<Company[]> = ref([]);
let lstDimensions: Ref<DimensionCompany[]> = ref([]);

let empresaSel: Ref<Company | null> = ref(null);
let departamentoSel: Ref<Departamento | null> = ref(null);
let vendorSel: Ref<Vendors | null> = ref(null);
let compradorSel: Ref<Empleado | null> = ref(null);
let bodegaSel: Ref<Location | null> = ref(null);
let proyectoSel: Ref<Proyecto | null> = ref(null);

let estado: Ref<string> = ref("");
let tipo: Ref<string> = ref("");
let solId: Ref<string> = ref("");
let solNombre: Ref<string> = ref("");
let nombreAutorizador: Ref<string> = ref("");
let motivo: Ref<string> = ref("");
let codigoDes: Ref<string> = ref("");
let idBc365: Ref<string> = ref("");
let enabledBC365: Ref<boolean> = ref(false);
let esComprador: Ref<boolean> = ref(false);
let esAdmin: Ref<boolean> = ref(false);
let esBodeguero: Ref<boolean> = ref(false);
let esAutorizador: Ref<boolean> = ref(false);
let esContabilidad: Ref<boolean> = ref(false);
let aprobador: Ref<string> = ref("");
let dialogRechazar: Ref<boolean> = ref(false);
let dialogAprobar: Ref<boolean> = ref(false);
let dialogAutorizar: Ref<boolean> = ref(false);
let dialogDespachar: Ref<boolean> = ref(false);
let dialogCerrar: Ref<boolean> = ref(false);
let lstDetalle = reactive<SolicitudDetalle[]>([]);
let selected: SolicitudDetalle[] = [];
let adjuntos: Adjunto[] = [];
let disabledCount: number = 0;
let total: number = 0;
let usuario: Ref<Empleado | null> = ref(null);
let autorizador: Ref<string> = ref("");

let headers: any = [
  { title: "", key: "isSelected", width: "25px" },
  { title: "Id", key: "id", align: "center", width: "50px" },
  { title: "Cod", key: "codigoMaterial", align: "center", width: "150px" },
  {
    title: "Material/Servicio",
    key: "material",
    align: "center",
    width: "100px",
  },
  { title: "UN", key: "unidad", align: "center", width: "90px" },
  { title: "Q", key: "cantidad", align: "center", width: "100px" },
  { title: "Precio", key: "precio", align: "center", width: "100px" },
  { title: "Total", key: "total", align: "center", width: "100px" },
  { title: "Ceco", key: "cecoId", align: "start", width: "150px" },
  { title: "Proyecto", key: "proyectoId", align: "center", width: "150px" },
  { title: "Bodega", key: "codigoBodega", align: "center", width: "100px" },
  {
    title: "Ped. Compra",
    key: "pedidoCompra",
    align: "center",
    width: "100px",
  },
  {
    title: "OT Mantención",
    key: "otMantencion",
    align: "center",
    width: "50px",
  },
];

let headersRetiro: any = [
  { title: "Id", key: "id", align: "center", width: "25px" },
  { title: "Cod", key: "codigoMaterial", align: "center", width: "150px" },
  {
    title: "Material/Servicio",
    key: "material",
    align: "start",
    width: "100px",
  },
  { title: "UN", key: "unidad", align: "center", width: "90px" },
  { title: "Q", key: "cantidad", align: "center", width: "100px" },
  { title: "Ceco", key: "cecoId", align: "start", width: "100px" },
  { title: "Proyecto", key: "proyectoId", align: "center", width: "200px" },
  {
    title: "OT Mantención",
    key: "otMantencion",
    align: "center",
    width: "50px",
  },
  {
    title: "Observación",
    key: "codigoDespacho",
    align: "center",
    width: "100px",
  },
  { title: "", key: "actions", align: "center", width: "100px" },
];

let headersRendicion: any = [
  { title: "Id", key: "id", width: "25px" },
  { title: "Tipo Docto", key: "tipoDoc", align: "center" },
  { title: "N° Docto", key: "nroDoc", align: "center" },
  { title: "Fecha Docto", key: "fechaDoc", align: "center" },
  { title: "Proveedor", key: "proveedorId", align: "center" },
  { title: "Material/Servicio", key: "material", align: "center" },
  { title: "Descripción del Gasto", key: "descGasto", align: "center" },
  { title: "Ceco", key: "cecoId", align: "center", width: "250px" },
  {
    title: "Proyecto",
    key: "proyectoId",
    align: "center",
    width: "250px",
  },
  { title: "Monto", key: "monto", align: "center", width: "150px" },
  {
    title: "OT Mantención",
    key: "otMantencion",
    align: "center",
    width: "50px",
  },
];

onMounted(async () => {
  try {
    const azureService = new AzureService();

    $msalInstance = new PublicClientApplication(
      azureService.getMsalConfig().value
    );

    store.state.titulo = "Detalle Solicitud";

    await getCompanies();
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      isLoading.value = false;
    }, 1000);
  }
});

async function obtenerEmpleado(email: string) {
  try {
    isLoading.value = true;

    await axios
      .get(store.state.url + "api/Empleado/GetEmpleadoXEmail?email=" + email, {
        headers: {},
      })
      .then(async (response) => {
        if (response.status == 200) {
          usuario.value = response.data;
          autorizador.value = response.data.rut;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

function getSolicitudCompra() {
  // isLoading.value = true;
  try {
    axios
      .get(
        store.state.url +
          "api/SolicitudCompra/GetSolicitudCompra?solicitudId=" +
          route.params.solicitudId
      )
      .then(async (response) => {
        if (response.status == 200) {
          var solicitud: SolicitudCompra = response.data as SolicitudCompra;

          let validarUsuario = await verificarUsuario(
            solicitud.encabezado.tipoSolicitudId || "",
            solicitud.encabezado.departamentoId || "",
            solicitud.encabezado.solicitantePorId || "",
            solicitud.encabezado.solicitanteId || ""
          );
          if (!validarUsuario) {
            swal.fire({
              title: "Error",
              text: "No tiene permisos para ver esta solicitud",
              icon: "error",
              confirmButtonText: "Aceptar",
            });

            router.push({ name: "BuscaSolicitud" });
            return;
          }

          idSolicitud = solicitud.encabezado.id || "";
          date.value = solicitud.encabezado.fechaSolicitud?.substr(0, 10) || "";
          solPorId.value = solicitud.encabezado.solicitantePorId || "";
          solPorNombre.value = solicitud.encabezado.solicitantePorNombre || "";
          code.value = solicitud.encabezado.code || 0;

          empresaSel.value =
            lstEmpresas.value.find(
              (r) => r.id == solicitud.encabezado.companyId
            ) || null;

          await getDepartamentos(empresaSel.value?.id || "");
          await getCeco(empresaSel.value?.id || "");
          await getBodegas(empresaSel.value?.id || "");
          await getVendors(empresaSel.value?.id || "");
          await getProyectos(empresaSel.value?.id || "");
          await getSolicitantes();
          await getDimensionsLines();
          await getEmpleadoComprador();

          await obtenerEmpleado(cookies.get("username"));

          departamentoSel.value =
            lstDepartamento.value.find(
              (d) => d.id == solicitud.encabezado.departamentoId
            ) || null;

          solId.value = solicitud.encabezado.solicitanteId || "";
          solNombre.value = solicitud.encabezado.solicitanteNombre || "";
          obs.value = solicitud.encabezado.observacion || "";

          adjuntos = (await getFiles(idSolicitud)) || [];

          vendorSel.value =
            lstVendors.value.find(
              (v) => v.id == solicitud.encabezado.vendorId
            ) || null;

          estado.value = solicitud.encabezado.estado || "";
          tipo.value = solicitud.encabezado.tipoSolicitudId || "";

          if (
            estado.value == "Autorizada" ||
            estado.value == "Aprobada" ||
            estado.value == "Rechazada" ||
            (estado.value == "Enviada" &&
              solicitud.encabezado.autorizadorId != null)
          ) {
            let soli =
              lstSolicitantes.value.find(
                (s) => s.rut == solicitud.encabezado.autorizadorId
              ) || null;

            nombreAutorizador.value = soli?.fullName || "";
            motivo.value = solicitud.encabezado.motivo || "";
          }

          if (tipo.value == "Retiro Bodega") {
            codigoDes.value = solicitud.encabezado.codigoDespacho || "";

            bodegaSel.value =
              lstBodegas.value.find(
                (b) => b.id == solicitud.encabezado.codigoBodega
              ) || null;
          }

          if (tipo.value == "Compra") {
            idBc365.value = solicitud.encabezado.bC365Id || "";

            bodegaSel.value =
              lstBodegas.value.find(
                (b) => b.id == solicitud.encabezado.codigoBodega
              ) || null;

            compradorSel.value =
              lstCompradores.value.find(
                (c) => c.rut == solicitud.encabezado.comprador
              ) || null;
          }

          for (let i = 0; i < solicitud.detalle.length; i++) {
            let ceco = lstCeco.value.find(
              (c) => c.id == solicitud.detalle[i].cecoId
            );

            if (ceco != null) {
              solicitud.detalle[i].cecoId = ceco.displayName;
            }

            if (tipo.value == "Retiro Bodega" || tipo.value == "Compra") {
              if (solicitud.detalle[i].proyectoId != null) {
                let projId = lstProyectos.value.find(
                  (p) => p.id == solicitud.detalle[i].proyectoId
                );

                solicitud.detalle[i].proyectoId = projId?.displayName;
              }

              if (solicitud.detalle[i].codigoBodega != null) {
                let codBod = lstBodegas.value.find(
                  (b) => b.id == solicitud.detalle[i].codigoBodega
                );

                solicitud.detalle[i].codigoBodega = codBod?.displayName;
              }

              solicitud.detalle[i].codigoDespacho =
                solicitud.detalle[i].codigoDespacho == null
                  ? ""
                  : solicitud.detalle[i].codigoDespacho;

              solicitud.detalle[i].total =
                solicitud.detalle[i].cantidad * solicitud.detalle[i].precio;

              if (tipo.value == "Compra") {
                solicitud.detalle[i].isSelected = false;
                if (solicitud.detalle[i].estadoDespacho == "Enviada") {
                  solicitud.detalle[i].isSelected = true;
                  selected.push(solicitud.detalle[i]);
                }
              }
            } else {
              if (tipo.value == "Rendicion") {
                if (solicitud.detalle[i].fechaDoc != null) {
                  solicitud.detalle[i].fechaDoc = solicitud.detalle[
                    i
                  ].fechaDoc?.substr(0, 10);
                }
                if (solicitud.detalle[i].proveedorId != null) {
                  var prov = lstVendors.value.find(
                    (v) => v.id == solicitud.detalle[i].proveedorId
                  );
                  if (prov != null) {
                    solicitud.detalle[i].proveedorId = prov.displayName;
                  } else {
                    solicitud.detalle[i].proveedorId = "";
                  }
                }
                if (solicitud.detalle[i].proyectoId != null) {
                  let projId = lstProyectos.value.find(
                    (p) => p.id == solicitud.detalle[i].proyectoId
                  );

                  solicitud.detalle[i].proyectoId = projId?.displayName;
                }
              }
            }
          }

          lstDetalle = solicitud.detalle;

          if (tipo.value == "Compra") {
            lstDetalle.map((item) => {
              if (item.estadoDespacho == "Enviada") {
                disabledCount += 1;
              }
            });

            compraTotal();
          } else {
            rendicionTotal();
          }

          // if (departamentoSel.value != null) {
          //   var rolDepto = await getRolDepto(
          //     cookies.get("username"),
          //     // "czambrano@oxxeanchile.cl",
          //     departamentoSel.value.code
          //   );

          //   if (rolDepto != null) {
          //     if (
          //       rolDepto.some((r) => r.rolEmail === "Aprobador") &&
          //       tipo.value != "Rendicion"
          //     ) {
          //       aprobador.value = "Aprobador";
          //     } else {
          //       if (
          //         rolDepto.some((r) => r.rolEmail === "Autorizador") &&
          //         tipo.value == "Rendicion"
          //       ) {
          //         aprobador.value = "Autorizador";
          //       }
          //     }
          //   }
          // }

          var roles = await getRol(cookies.get("username"));
          // var roles = await getRol("czambrano@oxxeanchile.cl");

          if (departamentoSel.value != null) {
            var rolDepto = await getRolDepto(
              cookies.get("username"),
              // "czambrano@oxxeanchile.cl",
              departamentoSel.value.code
            );

            if (rolDepto != null && roles != null) {
              if (
                roles.some((r: Rol) => r.rolNombre === "Aprobador") &&
                rolDepto.some((r) => r.rolEmail === "Aprobador") &&
                tipo.value != "Rendicion"
              ) {
                aprobador.value = "Aprobador";
              } else {
                if (
                  roles.some((r: Rol) => r.rolNombre === "Autorizador") &&
                  rolDepto.some((r) => r.rolEmail === "Autorizador") &&
                  tipo.value == "Rendicion"
                ) {
                  aprobador.value = "Autorizador";
                }
              }
            }
          }

          if (
            roles != null &&
            roles.some((r) => r.rolNombre === "Administrator")
          ) {
            esAdmin.value = true;
          }

          if (tipo.value == "Compra") {
            if (
              (estado.value == "Creada" ||
                estado.value == "Aprobada" ||
                estado.value == "En Envio") &&
              roles != null &&
              roles.some((r) => r.rolNombre === "Comprador")
            ) {
              esComprador.value = true;
            }
          } else {
            if (tipo.value == "Retiro Bodega") {
              if (
                estado.value == "Aprobada" &&
                roles != null &&
                roles.some((r) => r.rolNombre === "Bodega")
              ) {
                esBodeguero.value = true;
              }

              if (
                estado.value == "En Despacho" &&
                roles != null &&
                roles.some((r) => r.rolNombre === "Bodega")
              ) {
                esBodeguero.value = true;
              }
            } else {
              if (tipo.value == "Rendicion") {
                if (
                  estado.value == "Creada" &&
                  roles != null &&
                  roles.some((r) => r.rolNombre === "Autorizador")
                ) {
                  esAutorizador.value = true;
                } else {
                  if (
                    estado.value == "Autorizada" &&
                    roles != null &&
                    roles.some((r) => r.rolNombre === "Contabilidad")
                  ) {
                    esContabilidad.value = true;
                  }
                }
              }
            }
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });

    isLoading.value = false;
  } catch (error) {
    console.log(error);
  } finally {
    // isLoading.value = false;
  }
}

async function getDeptosRol(email: string, rol: string) {
  try {
    var deptos: string[] = [];

    await axios
      .get(
        store.state.url + "api/Rol/GeDeptostRol?email=" + email + "&rol=" + rol,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          deptos = response.data;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });

    return deptos;
  } catch (error) {
    console.log(error);
  }
}

async function verificarUsuario(
  tipo: string,
  depto: string,
  solPorId: string,
  solId: string
) {
  let verificado = false;

  await obtenerEmpleado(cookies.get("username"));
  // await this.obtenerEmpleado("czambrano@oxxeanchile.cl");

  var roles = await getRol(cookies.get("username"));
  // var roles = await this.getRol("czambrano@oxxeanchile.cl");

  if (roles != null && roles.some((r) => r.rolNombre === "Administrador")) {
    return true;
  }

  if (usuario != null && usuario.value.rut == solId) {
    return true;
  }

  if (usuario != null && usuario.value.rut == solPorId) {
    return true;
  }

  if (
    tipo == "Compra" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Comprador")
  ) {
    verificado = true;
  }

  if (
    tipo == "Retiro Bodega" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Bodega")
  ) {
    verificado = true;
  }

  if (
    tipo == "Rendicion" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Contabilidad")
  ) {
    verificado = true;
  }

  if (
    (tipo == "Compra" || tipo == "Retiro Bodega") &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Visualizador")
  ) {
    let deptosViusalizador = await getDeptosRol(
      cookies.get("username"),
      // "czambrano@oxxeanchile.cl",
      "Visualizador"
    );

    if (deptosViusalizador?.some((d) => d == depto)) {
      verificado = true;
    }
  }

  if (
    tipo == "Rendicion" &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Autorizador")
  ) {
    let deptosAutorizador = await getDeptosRol(
      cookies.get("username"),
      // "czambrano@oxxeanchile.cl",
      "Autorizador"
    );

    if (deptosAutorizador?.some((d) => d == depto)) {
      verificado = true;
    }
  }

  if (
    (tipo == "Compra" || tipo == "Retiro Bodega") &&
    roles != null &&
    roles.some((r) => r.rolNombre === "Aprobador")
  ) {
    let deptosAprobador = await getDeptosRol(
      (roles != null && cookies.get("username")) || "",
      // "czambrano@oxxeanchile.cl",
      "Aprobador"
    );

    if (deptosAprobador?.some((d) => d == depto)) {
      verificado = true;
    }
  }

  return verificado;
}

async function enviarBusinessCentral() {
  try {
    enabledBC365.value = false;

    if (vendorSel.value == null) {
      swal.fire({
        icon: "error",
        title: "Error al enviar a Business Central",
        text: "Debe seleccionar un proveedor",
      });

      enabledBC365.value = true;
      return;
    }

    if (
      lstDetalle.filter((d) => d.estadoDespacho != "Enviada" && d.isSelected)
        .length == 0
    ) {
      swal.fire({
        icon: "error",
        title: "Error al enviar a Business Central",
        text: "Debe seleccionar al menos un Item",
      });

      enabledBC365.value = true;
      return;
    }

    var purchaseOrdersBody: PurchaseOrdersBody = {
      orderDate: date.value,
      postingDate: date.value,
      vendorId: vendorSel.value.id,
      vendorNumber: vendorSel.value.number,
      payToVendorId: vendorSel.value.id,
      payToVendorNumber: vendorSel.value.number,
      buyFromAddressLine1: "",
      buyFromAddressLine2: "",
      buyFromCity: "",
      buyFromCountry: "",
      currencyId: "00000000-0000-0000-0000-000000000000",
      currencyCode: "EUR",
      pricesIncludeTax: false,
      paymentTermsId: vendorSel.value.paymentTermsId,
      shipmentMethodId: "00000000-0000-0000-0000-000000000000",
      requestedReceiptDate: "0001-01-01",
      discountAmount: 0,
      fullyReceived: false,
      shipToCity: "",
      shipToName: "",
      shipToState: "",
      buyFromState: "",
      shipToContact: "",
      shipToCountry: "",
      shipToPostCode: "",
      buyFromPostCode: "",
      shipToAddressLine1: "",
      shipToAddressLine2: "",
      shortcutDimension1Code: "",
      shortcutDimension2Code: "",
      purchaser: "",
      purchaseOrderLines: [],
      dimensionSetLines: [],
    };

    for (var det of lstDetalle.filter(
      (d) =>
        (d.retiroBodega == false || d.retiroBodega == null) &&
        d.estadoDespacho != "Enviada" &&
        d.isSelected
    )) {
      let ceco = lstCeco.value.find((c) => c.displayName == det.cecoId);

      let lines: PurchaseOrderLine = {
        lineNumber: det.id,
        itemId: det.idItem || "",
        quantity: det.cantidad || 0,
        // dimensionSetLines: [
        //   {
        //     id:
        //       lstDimensions.value.filter((d) => d.dimensionName == "CECO")[0]
        //         .dimensionId || "",
        //     valueId: ceco?.id || "",
        //   },
        //   {
        //     id:
        //       lstDimensions.value.filter((d) => d.dimensionName == "DPTO")[0]
        //         .dimensionId || "",
        //     valueId: departamentoSel.value?.id || "",
        //   },
        // ],
      } as PurchaseOrderLine;

      // if (proyectoSel.value != null) {
      //   lines.dimensionSetLines?.push({
      //     id:
      //       lstDimensions.value.filter((d) => d.dimensionName == "PROYECTO")[0]
      //         .dimensionId || "",
      //     valueId: proyectoSel.value.id,
      //   });
      // }

      purchaseOrdersBody.purchaseOrderLines?.push(lines);
    }

    let comment: PurchaseOrderLine = {
      lineType: "Comment",
      description: "SOC-" + code.value.toString().padStart(7, "0"),
    } as PurchaseOrderLine;

    purchaseOrdersBody.purchaseOrderLines?.push(comment);

    // if (
    //   purchaseOrdersBody.dimensionSetLines?.some(
    //     (d: DimensionSetLine) =>
    //       d.id ==
    //       lstDimensions.value.filter((dl) => dl.dimensionName == "DPTO")[0]
    //         .dimensionId
    //   ) == false
    // ) {
    //   purchaseOrdersBody.dimensionSetLines.push({
    //     id:
    //       lstDimensions.value.filter((d) => d.dimensionName == "DPTO")[0]
    //         .dimensionId || "",
    //     valueId: departamentoSel.value?.id || "",
    //   });
    // }

    isLoading.value = true;
    // console.log(JSON.stringify(selected));

    await axios
      .post(
        store.state.url +
          "api/SolicitudCompra/PostPurchaseOrder?company=" +
          empresaSel.value?.id +
          "&idSol=" +
          idSolicitud +
          "&usuario=" +
          autorizador,
        purchaseOrdersBody
      )
      .then((response) => {
        if (response.status == 200 && response.data.number != null) {
          if (
            lstDetalle.filter((d) => d.isSelected).length == lstDetalle.length
          ) {
            cambiarEstadoSolicitud("Enviada");
          } else {
            cambiarEstadoSolicitud("En Envio");
          }

          router.push({ name: "BuscaSolicitud" });
        } else {
          console.log("error al enviar: " + response.data.error.message);

          swal.fire({
            icon: "error",
            title: "Error al enviar a Business Central",
            text: response.data.error.message,
          });
        }
      })
      .catch((error) => {
        // errorMessage = error.message;
        console.error("There was an error!", error);

        swal.fire({
          icon: "error",
          title: "Error al enviar a Business Central",
          text: error.message,
        });
      })
      .finally(() => {
        dialogAprobar.value = false;
        isLoading.value = false;
        enabledBC365.value = true;
      });
  } catch (error) {
    // errorMessage = error.message;
    console.error("There was an error!", error);
  } finally {
    isLoading.value = false;
    enabledBC365.value = true;
  }
}

async function enviarRendicion() {
  var errorMsg: string[] = [];
  var enviadas = [];

  enabledBC365.value = false;
  isLoading.value = true;

  await axios
    .post(
      store.state.url +
        "api/Rendicion/EnviarRendicion?rendicionId=" +
        idSolicitud +
        "&company=" +
        empresaSel.value?.id +
        "&usuario=" +
        autorizador.value
      // selectedSol
    )
    .then(async (response) => {
      if (response.status == 200) {
        for (var res of response.data) {
          if (res.message.includes("error")) {
            errorMsg.push(res.message + "\n\n");
          } else {
            enviadas.push(res.solicitudId);
          }
        }

        if (errorMsg.length > 0) {
          swal.fire({
            icon: "error",
            title:
              "Error al enviar " +
              errorMsg.length +
              " solicitudes a Business Central",
            text: errorMsg.join(),
          });
        } else {
          console.log("Solicitudes enviadas correctamente");

          estado.value = "Enviada";

          swal.fire({
            icon: "success",
            title: "Rendiciones enviadas correctamente",
            text:
              "Se enviaron " +
              enviadas.length +
              " detalle(s) de la solicitud SOC-" +
              code.value.toString().padStart(7, "0"),
          });

          router.push({ name: "BuscaSolicitud" });
        }
      } else {
        console.log(response.statusText);

        swal.fire({
          icon: "error",
          title: "Error al enviar Rendición a Business Central",
          text: response.statusText,
        });
      }
    })
    .catch((error) => {
      //errorMessage = error.message;
      console.error("There was an error!", error);

      swal.fire({
        icon: "error",
        title: "Error al enviar Rendición a Business Central",
        text: error.message,
      });
    })
    .finally(() => {
      isLoading.value = false;
      enabledBC365.value = true;
    });
}

function getCompanies() {
  try {
    axios
      .get(store.state.url + "api/Company/GetCompanies", { headers: {} })
      .then(async (response) => {
        if (response.status == 200) {
          lstEmpresas.value = response.data as Company[];
          // isLoading.value = true;

          getSolicitudCompra();
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {
    console.log(error);
  }
}

async function getDimensionsLines() {
  try {
    if (empresaSel.value == null) {
      return;
    }

    await axios
      .get(
        store.state.url +
          "api/SolicitudCompra/GetDimensionsId?company=" +
          empresaSel.value.id,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          lstDimensions.value = response.data as DimensionCompany[];
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });
  } catch (error) {}
}

async function getFiles(idSolicitud: string) {
  try {
    var archivos: Adjunto[] = [];

    await axios
      .get(
        store.state.url +
          "api/SolicitudCompra/GetSolicitudFiles?solicitudId=" +
          idSolicitud
      )
      .then((response) => {
        if (response.status == 200) {
          archivos = response.data;
        } else {
          console.log(response.statusText);
        }
      })
      .catch(function (error) {
        console.log(error.message);
      });

    return archivos;
  } catch (error) {
    console.log(error);
  }
}

function updateComprador() {
  try {
    if (compradorSel.value == null) {
      swal.fire({
        icon: "error",
        title: "Error al actualizar comprador",
        text: "Debe seleccionar un comprador",
      });

      return;
    }

    isLoading.value = true;

    axios
      .put(
        store.state.url +
          "api/SolicitudCompra/UpdateComprador?id=" +
          idSolicitud +
          "&compradorId=" +
          compradorSel.value.rut
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("comprador actualizado Exitosamente");

          swal.fire({
            icon: "success",
            title: "Comprador actualizado",
            showConfirmButton: false,
            timer: 2000,
          });
        }
      })
      .catch((error) => {
        // errorMessage = error.message;
        console.error(error.message);
      });
  } catch (error) {
    // errorMessage = error.message;
    console.error("There was an error!", error);
  } finally {
    isLoading.value = false;
  }
}

async function getRolDepto(email: string, depto: string) {
  try {
    var tiene: DepartamentoEmpleado[] | null = [];

    await axios
      .get(
        store.state.url +
          "api/Rol/GetRolDepto?email=" +
          email +
          "&depto=" +
          depto,
        {
          headers: {},
        }
      )
      .then((response) => {
        if (response.status == 200) {
          //tieneRol = response.data;
          //console.log(response.data);
          tiene = response.data;
        } else {
          console.log(response.statusText);
          tiene = null;
        }
      })
      .catch(function (error) {
        console.log(error.message);
        tiene = null;
      });

    return tiene;
  } catch (error) {
    console.log(error);
  }
}

function bajarAdjunto(filename: string) {
  try {
    isLoading.value = true;

    const link = document.createElement("a");
    link.href =
      store.state.url + "api/SolicitudCompra/DownloadFile?fileName=" + filename;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log(error);
  } finally {
    isLoading.value = false;
  }
}

async function getRol(email: string) {
  try {
    var tiene: Rol[] = [];

    await axios
      .get(store.state.url + "api/Rol/GetRol?email=" + email, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          tiene = response.data as Rol[];
        } else {
          console.log(response.statusText);
          tiene = [];
        }
      })
      .catch(function (error) {
        console.log(error.message);
        tiene = [];
      });

    return tiene;
  } catch (error) {
    console.log(error);
  }
}

function getEstadoDespacho(item: SolicitudDetalle) {
  if (item.estadoDespacho == "Despachado") {
    return false;
  } else {
    return true;
  }
}

function despacharItem(despachado: SolicitudDetalle) {
  if (despachado.codigoDespacho == "") {
    swal.fire({
      icon: "error",
      title: "Error al despachar",
      text: "Debe ingresar un codigo de despacho",
    });

    return;
  }

  try {
    axios
      .put(
        store.state.url +
          "api/SolicitudCompra/DespacharItem?usuario=" +
          autorizador.value,
        despachado,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        if (response.status == 200) {
          var res = response.data;

          // console.log(res);
          if (res.estado == "Despachado") {
            despachado.estadoDespacho = "Despachado";
            estado.value = "Despachado";
            codigoDes.value = res.codigoDespacho;

            router.push({ name: "BuscaSolicitud" });
          } else {
            despachado.estadoDespacho = "Despachado";
            estado.value = "En Despacho";
            codigoDes.value = res.codigoDespacho;
          }

          console.log("Item despachado Exitosamente");

          swal.fire({
            icon: "success",
            title: "Item despachado Exitosamente",
            showConfirmButton: false,
            timer: 2000,
          });
        } else {
          console.log(response.statusText);

          swal.fire({
            icon: "error",
            title: "Error al despachar",
            text: response.statusText,
          });
        }
      })
      .catch((error) => {
        //errorMessage = error.message;
        console.error(error);

        swal.fire({
          icon: "error",
          title: "Error al despachar",
          text: error.message,
        });
      })
      .finally(() => {
        dialogRechazar.value = false;
        motivo.value = "";
      });
  } catch (error) {
    //errorMessage = error.message;
    console.error("There was an error!", error);
  } finally {
    isLoading.value = false;
  }
}

function despacharSolicitud() {
  try {
    //verify if item must have at least one despacho

    if (lstDetalle.some((d) => d.codigoDespacho != "") == false) {
      dialogDespachar.value = false;

      swal.fire({
        icon: "error",
        title: "Error al despachar",
        text: "Debe ingresar al menos un codigo de despacho",
      });

      return;
    }

    let despachados = lstDetalle.filter(
      (d) => d.codigoDespacho != "" && d.estadoDespacho != "Despachado"
    );

    axios
      .put(
        store.state.url +
          "api/SolicitudCompra/DespacharSolicitud?solId=" +
          idSolicitud +
          "&usuario=" +
          autorizador,
        despachados,
        { headers: { "Content-Type": "application/json" } }
      )
      .then((response) => {
        if (response.status == 200) {
          estado = response.data;
          console.log("Solicitud " + response.data);

          dialogDespachar.value = false;

          swal.fire({
            icon: "success",
            title: "Solicitud " + response.data,
            showConfirmButton: false,
            timer: 2000,
          });

          //getSolicitudCompra();
          router.push({ name: "BuscaSolicitud" });
        } else {
          console.log(response.statusText);

          swal.fire({
            icon: "error",
            title: "Error al despachar",
            text: response.statusText,
          });
        }
      })
      .catch((error) => {
        //errorMessage = error.message;
        console.error(error);
      });
  } catch (error: any) {
    //errorMessage = error.message;
    console.error("There was an error!", error);

    swal.fire({
      icon: "error",
      title: "Error al despachar",
      text: error.message,
    });
  }
}

function cambiarEstadoSolicitud(nuevoEstado: string) {
  try {
    let url = "";

    if (
      nuevoEstado == "Aprobada" ||
      nuevoEstado == "Autorizada" ||
      nuevoEstado == "Rechazada" ||
      nuevoEstado == "Anulada"
    ) {
      url =
        store.state.url +
        "api/SolicitudCompra/UpdateEstado?id=" +
        idSolicitud +
        "&estado=" +
        nuevoEstado +
        "&autorizadorId=" +
        autorizador.value +
        "&motivo=" +
        motivo.value;
    } else {
      url =
        store.state.url +
        "api/SolicitudCompra/UpdateEstado?id=" +
        idSolicitud +
        "&estado=" +
        nuevoEstado;
    }

    axios
      .put(url)
      .then((response) => {
        if (response.status == 200) {
          if (nuevoEstado == "Autorizada") {
            estado.value = "Autorizada";
            console.log("Solicitud Autorizada Exitosamente");

            dialogAutorizar.value = false;

            sendEmail(idSolicitud);

            swal.fire({
              icon: "success",
              title: "Solicitud Autorizada",
              showConfirmButton: false,
              timer: 2000,
            });

            router.push({ name: "BuscaSolicitud" });
          } else {
            if (nuevoEstado == "Aprobada") {
              estado.value = "Aprobada";
              console.log("Solicitud Aprobada Exitosamente");

              dialogAprobar.value = false;

              sendEmail(idSolicitud);

              swal.fire({
                icon: "success",
                title: "Solicitud Aprobada",
                showConfirmButton: false,
                timer: 2000,
              });

              router.push({ name: "BuscaSolicitud" });
            }

            if (nuevoEstado == "Guardada") {
              estado.value = "Guardada";
              console.log("Solicitud Guardada Exitosamente");

              router.push({
                name: "EditarSolicitud",
                params: { solicitudId: route.params.solicitudId },
              });
            }

            if (nuevoEstado == "Rechazada") {
              console.log("Solicitud Rechazada Exitosamente");
              estado.value = "Rechazada";

              dialogRechazar.value = false;

              // sendEmail(idSolicitud);

              swal.fire({
                icon: "success",
                title: "Solicitud Rechazada",
                showConfirmButton: false,
                timer: 2000,
              });

              router.push({ name: "BuscaSolicitud" });
            }

            if (nuevoEstado == "Enviada") {
              estado.value = "Enviada";
              console.log("Solicitud Enviada a Business Central Exitosa");

              // dialogAprobar = false;

              swal.fire({
                icon: "success",
                title: "Solicitud Enviada a Business Central Exitosa",
                showConfirmButton: false,
                timer: 2000,
              });
            }
          }
        }
      })
      .catch((error) => {
        //errorMessage = error.message;
        console.error(error);
      });
  } catch (error) {
    // errorMessage = error.message;
    console.error("There was an error!", error);
  }
}

function anularSolicitud() {
  try {
    axios
      .put(
        store.state.url +
          "api/SolicitudCompra/UpdateEstado?id=" +
          idSolicitud +
          "&estado=Anulada"
      )
      .then((response) => {
        if (response.status == 200) {
          console.log("Solicitud Anulada");

          dialogAutorizar.value = false;

          swal.fire({
            icon: "success",
            title: "Solicitud anulada",
            showConfirmButton: false,
            timer: 2000,
          });

          router.push({ name: "BuscaSolicitud" });
        } else {
          console.log(response.statusText);

          swal.fire({
            icon: "error",
            title: "Error al anular",
            text: response.statusText,
          });
        }
      })
      .catch((error) => {
        // errorMessage = error.message;
        console.error(error);

        swal.fire({
          icon: "error",
          title: "Error al anular",
          text: error.message,
        });
      });
  } catch (error) {
    // errorMessage = error.message;
    console.error("There was an error!", error);
  }
}

function cerrarDespachado() {
  try {
    axios
      .put(
        store.state.url +
          "api/SolicitudCompra/CerrarDespachado?id=" +
          idSolicitud +
          "&usuario=" +
          autorizador
      )
      .then((response) => {
        if (response.status == 200) {
          estado.value = "Cerrada";
          console.log("Solicitud Despachada Exitosamente");

          dialogCerrar.value = false;

          swal.fire({
            icon: "success",
            title: "Solicitud Cerrada",
            showConfirmButton: false,
            timer: 2000,
          });

          router.push({ name: "BuscaSolicitud" });
        } else {
          console.log(response.statusText);

          swal.fire({
            icon: "error",
            title: "Error al cerrar",
            text: response.statusText,
          });
        }
      })
      .catch((error) => {
        //errorMessage = error.message;
        console.error(error);

        swal.fire({
          icon: "error",
          title: "Error al cerrar",
          text: error.message,
        });
      });
  } catch (error) {
    // errorMessage = error.message;
    console.error("There was an error!", error);
  }
}

async function sendEmail(idSol: string | undefined) {
  try {
    await refreshToken()
      .then(async () => {
        var token = cookies.get("token");

        await axios
          .post(
            store.state.url + "api/SolicitudCompra/SendEmail?idSol=" + idSol,
            token,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            if (response.status == 200) {
              console.log(response.data);
            } else {
              console.log(response.statusText);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      })
      .catch((error: any) => {
        console.log(error);
      });
  } catch (error) {
    console.log(error);
  }
}

async function refreshToken() {
  try {
    await $msalInstance.initialize();

    var redirectResponse = await $msalInstance.handleRedirectPromise();
    if (redirectResponse !== null) {
      cookies.set("token", redirectResponse.accessToken);
    } else {
      var myAcccount = $msalInstance.getAllAccounts();

      await $msalInstance
        .acquireTokenSilent({
          scopes: ["User.Read", "Mail.Send"],
          account: myAcccount[0],
        })
        .then((response: any) => {
          cookies.set("token", response.accessToken);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  } catch (error) {
    console.log(error);
  }
}

function setIcon(fileName: string) {
  try {
    var icon = "";

    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);

    switch (ext) {
      case "pdf":
        icon = "mdi-file-pdf-box";
        break;

      case "doc":
        icon = "mdi-file-word";
        break;

      case "docx":
        icon = "mdi-file-word";
        break;

      case "xls":
        icon = "mdi-file-excel";
        break;

      case "xlsx":
        icon = "mdi-file-excel";
        break;

      case "ppt":
        icon = "mdi-file-powerpoint";
        break;

      case "pptx":
        icon = "mdi-file-powerpoint";
        break;

      case "jpeg":
        icon = "mdi-file-image";
        break;

      case "png":
        icon = "mdi-file-image";
        break;

      case "gif":
        icon = "mdi-file-image";
        break;

      case "jpg":
        icon = "mdi-file-image";
        break;

      case "7z":
        icon = "mdi-zip-box";
        break;

      case "rar":
        icon = "mdi-zip-box";
        break;

      case "zip":
        icon = "mdi-zip-box";
        break;

      case "txt":
        icon = "mdi-file-document";
        break;

      default:
        icon = "mdi-file";
        break;
    }

    // console.log(icon);

    return icon;
  } catch (error) {
    console.log(error);
  }
}

function sliceString(value: string) {
  return value.substring(value.lastIndexOf("/") + 1);
}

function exportarPdf(title: string) {
  isLoading.value = true;

  var url =
    store.state.url +
    "api/SolicitudCompra/ExportarSolicitud?idSol=" +
    idSolicitud;

  axios({
    method: "get",
    url,
    responseType: "arraybuffer",
  })
    .then((response) => {
      forceFileDownload(response, title);
    })
    .catch(() => console.log("error occured"))
    .finally(() => {
      isLoading.value = false;
    });
}

function forceFileDownload(response: any, title: string) {
  //console.log(title);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", title);
  document.body.appendChild(link);
  link.click();
}

function compraTotal() {
  total = lstDetalle.reduce((acc, item) => acc + item.total, 0);
}

function rendicionTotal() {
  total = lstDetalle.reduce((acc, item) => acc + item.monto, 0);
}

function formatMoney(value: number) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
    minimumFractionDigits: 2,
  }).format(value);
}

function formatTotal(value: number) {
  return new Intl.NumberFormat("es-CL", {
    style: "currency",
    currency: "CLP",
  }).format(value);
}

function navigate() {
  router.push({
    name: "SolCompraParam",
    params: { solicitudId: idSolicitud },
  });
}
</script>
