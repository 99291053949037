import type { IMsalConfig } from '@/interfaces/IMsalConfig';
import type {Ref} from 'vue';
import {ref} from 'vue';

class AzureService {
    private msalConfig: Ref<IMsalConfig>;
    private accessToken: Ref<string>;

    constructor() {
        this.msalConfig = ref({
            auth: {
                clientId: "0fefbe10-767f-4705-bc80-16cf5b060938",
                authority: "https://login.microsoftonline.com/8ada87e4-81f7-4d95-9d9f-bc086bdcd438",
            },
            cache: {
                cacheLocation: "localStorage"
            }
        });

        this.accessToken = ref("");
    }

    setAccessToken(token : string): Ref<string> {
        this.accessToken.value = token;
        return this.accessToken;
    }

    getAccessToken(): Ref<string> {
        return this.accessToken;
    }

    getMsalConfig(): Ref<IMsalConfig> {
        return this.msalConfig;
    }
}

export default AzureService;