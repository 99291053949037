<style>
a:hover {
  background-color: transparent !important;
}

.v-list-item--density-default.v-list-item--one-line {
  min-height: 60px;
  padding-top: 4px;
  padding-bottom: 4px;
  border-bottom: 0.5px solid #ddd;
}
</style>
<template>
  <v-app>
    <v-app-bar color="#0071bc" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <img src="./assets/blanco.svg" width="200" height="80" />

      <div style="margin-left: 2rem">
        <h3>{{ $store.state.titulo }}</h3>
      </div>

      <v-app-bar-title></v-app-bar-title>

      <v-spacer></v-spacer>

      <label style="margin-right: 20px"> {{ $store.state.nombre }} </label>
      <div v-if="!$store.state.isLogged">
        <v-btn variant="text" icon="mdi-login" v-on:click="login()"></v-btn>
      </div>
    </v-app-bar>

    <v-navigation-drawer app v-model="drawer" color="#646363">
      <v-list>
        <v-list-item>
          <router-link
            to="/SolCompra"
            class="menu"
            style="color: white; font-size: 16px"
            >Crear Solicitud</router-link
          >
        </v-list-item>

        <v-list-item>
          <router-link
            to="/BuscaSolicitud"
            class="menu"
            style="color: white; font-size: 16px"
            >Buscar Solicitud</router-link
          >
        </v-list-item>

        <v-list-item v-if="esAdmin">
          <router-link
            to="/RolMantenedor"
            class="menu"
            style="color: white; font-size: 16px"
            >Mantenedor Roles</router-link
          >
        </v-list-item>

        <v-list-item v-if="esAdmin">
          <router-link
            to="/DeptoRolMantenedor"
            class="menu"
            style="color: white; font-size: 16px"
            >Mantenedor Departamento/Rol</router-link
          >
        </v-list-item>

        <v-list-item v-if="esAdmin">
          <router-link
            to="/EmpleadoMantenedor"
            class="menu"
            style="color: white; font-size: 16px"
            >Mantenedor Empleados</router-link
          >
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2" v-if="$store.state.isLogged">
          <v-btn
            v-on:click="logout()"
            style=" background-color: #0071bc; padding: 15px; border-radius: 15px; color: white; margin-left: auto; margin-right: auto; display: flex; "
          >
            Cerrar sesion
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main fluid>
      <router-view></router-view>
    </v-main>
  </v-app>

  <!-- <loading v-model:active="isLoading" :is-full-page="fullPage" /> -->
</template>

<script setup lang="ts">
import { ref } from "vue";
import { PublicClientApplication } from "@azure/msal-browser";
import { onMounted } from "vue";
import AzureService from "./services/AzureService";
import { useStore } from "vuex";
import { key } from "@/store";
import Emmitter from "tiny-emitter";
import { useCookies } from "vue3-cookies";
import { useRouter, useRoute } from "vue-router";
import type { Rol } from "./types/rol";
import axios from "axios";

const { cookies } = useCookies();
const store = useStore(key);
const router = useRouter();
const route = useRoute();

const drawer = ref<boolean>(false);
// const isAuthenticated = ref<boolean>(false);
let esAdmin: boolean = false;
// let isLoading: boolean = false;
let roles: Rol[] | undefined = [];

let $msalInstance: PublicClientApplication;
const $emitter = new Emmitter.TinyEmitter();

onMounted(async () => {
  roles = await getRol(cookies.get("username"));
  // roles = await getRol("eherrera@oxxeanchile.cl");

  if (roles && roles.some((r) => r.rolNombre === "Administrador")) {
    esAdmin = true;
  }

  const azureService = new AzureService();
  $msalInstance = new PublicClientApplication(
    azureService.getMsalConfig().value
  );

  await $msalInstance.initialize();
  await $msalInstance.handleRedirectPromise();
  $msalInstance.acquireTokenSilent({
    scopes: ["user.read", "mail.send"],
  });

  if (cookies.isKey("isLogged")) {
    store.state.isLogged = cookies.get("isLogged") === "true";
  }

  if (cookies.isKey("account")) {
    store.state.account = cookies.get("account");
    store.state.nombre = store.state.account.name; //this.account.name;
  }
});

async function login() {
  await $msalInstance
    .loginPopup()
    .then((response) => {
      const myAcccounts = $msalInstance.getAllAccounts();

      // console.log(myAcccounts[0]);

      $emitter.emit("login", myAcccounts[0]);

      // cookies.set("isLogged", "true");
      cookies.set("token", response.accessToken);
      //console.log("token: " + response.accessToken);
      cookies.set("account", store.state.account);
      cookies.set(
        "username",
        store.state.account.username ?? store.state.account.preferred_username
      );

      store.state.isLogged = true;
      store.state.nombre = myAcccounts[0].name;

      localStorage.removeItem("empresaSel");
      localStorage.removeItem("departamento");
      localStorage.removeItem("solId");
      localStorage.removeItem("ingresado");
      localStorage.removeItem("solicitante");
      localStorage.removeItem("tipoSol");
      localStorage.removeItem("bodega");
      localStorage.removeItem("bc365");
      localStorage.removeItem("fecha");
      localStorage.removeItem("estadoSel");
      localStorage.removeItem("comprador");

      router.push({ name: "BuscaSolicitud" });
    })
    .catch((error: string) => {
      console.log(error);
    });
}

async function logout() {
  store.state.nombre = "Invitado";
  store.state.isLogged = false;
  store.state.email = "";

  cookies.remove("isLogged");
  cookies.remove("token");
  cookies.remove("account");

  localStorage.removeItem("empresaSel");
  localStorage.removeItem("departamento");
  localStorage.removeItem("solId");
  localStorage.removeItem("ingresado");
  localStorage.removeItem("solicitante");
  localStorage.removeItem("tipoSol");
  localStorage.removeItem("bodega");
  localStorage.removeItem("bc365");
  localStorage.removeItem("fecha");
  localStorage.removeItem("estadoSel");
  localStorage.removeItem("comprador");

  await $msalInstance.logout();
  $emitter.emit("logout");
}

async function getRol(email: string) {
  try {
    let tiene: Rol[] = [];

    // isLoading = true;

    await axios
      .get(store.state.url + "api/Rol/GetRol?email=" + email, {
        headers: {},
      })
      .then((response) => {
        if (response.status == 200) {
          tiene = response.data;
        } else {
          tiene = [];
        }
      })
      .catch(function (error) {
        console.log(error.message);
        tiene = [];
      });

    return tiene;
  } catch (error) {
    console.log(error);
  } finally {
    // isLoading = false;
  }
}
</script>
